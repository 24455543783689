import { OpenAIClient, AzureKeyCredential } from "@azure/openai";
import { useCallback } from "react";

const client = new OpenAIClient(
  "https://avacodo-ai.openai.azure.com/",
  new AzureKeyCredential(process.env.REACT_APP_OPENAI_API_KEY || '')
);

export const useOpenAI = () => {
  //answer with explanation
  const chatCompletions = useCallback(async (question: string) => {
    return await client.getChatCompletions(
      "gpt-35-turbo", // assumes a matching model deployment or model name
      [{ role: "user", content: question }]
    );
  }, []);

  //specify the answer only
  const completions = useCallback(async (question: string) => {
    const options = { stop:["\n"], temperature: 0}

    return await client.getCompletions(
      "gpt-35-turbo", // assumes a matching model deployment or model name
       [question],
       options
    );
  }, []);

  return { chatCompletions, completions };
};
