import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { login, validateToken, Sessions as SessionsApi } from '../utils/api';
import { loggedIn as setLoggedIn } from '../state/reducer';
import { joinSession } from '../middleware/signalr';
import Sessions from '../controls/Sessions';
import Help from './Help';
import Loading from '../controls/Loading';
import css from './LoginModal.module.css';

export default function LoginModal({ onClose }: { onClose?: () => void }) {
    const [loginWait, setLoginWait] = useState(false);
    const [sessionName, setSessionName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [tokenChecked, setTokenChecked] = useState(false);
    const { sessionId, loggedIn, userName } = useAppSelector((state) => state.main);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const checkToken = async () => {
            const token = localStorage.getItem("token");
            if (token) {
                await dispatch(validateToken(token));
            }
            else dispatch(setLoggedIn(false));

            setTokenChecked(true);
        };


        checkToken();
    }, [dispatch]);

    useEffect(() => {
        if (sessionId) {
            dispatch(joinSession(sessionId));
            onClose && onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, dispatch]);

    useEffect(() => {
        setLoginWait(false);
    }, [loggedIn]);

    return <>
        <div className={css.modal}>
            <div className={css.form}>
                <Loading text="One moment" textStyle={{fontWeight: 500}} enabled={!tokenChecked} style={{ height: "100%", overflow: "auto" }}>
                    <div style={{ height: "100%" }}>
                        <div id={css.login}>
                            {/* Session Code: <input type="text" value={sessionId} onChange={e => { setSessionId(e.target.value); }} /><br /> */}
                            {loggedIn !== true && <div id="loginForm" className={css.mainSection}>
                                <h1>Interviewer Login</h1>
                                Email: <input type="text" value={email} onChange={(e) => { setEmail(e.target.value); }} /><br />
                                Password: <input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} /><br />
                                <Loading enabled={loginWait}><button onClick={() => { dispatch(login(email, password)); setLoginWait(true); }}>Login</button></Loading>
                                {loggedIn !== false && <div>Error: {loggedIn}</div>}
                            </div>}
                            {loggedIn === true && <div className={css.mainSection}>
                                <div>Welcome back {userName}!</div>
                                Session Name: <input type="text" value={sessionName} onChange={({ target }) => { setSessionName(target.value); }} />
                                <button onClick={() => {
                                    dispatch(SessionsApi.create(sessionName))
                                }}>Create Session</button>
                                <Sessions />
                            </div>}
                            <div id="help" className={css.rightSection}>
                                <Help />
                            </div>
                        </div>
                    </div>
                </Loading>
            </div>
        </div>
    </>;
}