import { useCallback, useEffect, useState } from "react";

import Editor from "./controls/Editor";
import { useAppDispatch, useAppSelector } from "./hooks/hooks";
import LoginModal from "./views/LoginModal";
import {
  setAIQuestion,
  setEditorLanguage,
  setUserName,
  transmitPosition,
} from "./state/reducer";
import qs from "qs";
import { exchange, Sessions, validateToken } from "./utils/api";
import jwtDecode from "jwt-decode";
import GuestManager from "./views/GuestManager";
import { joinSession, leaveSession } from "./middleware/signalr";
import Helpers from "./utils/helpers";
import ConnectedUsers from "./controls/ConnectedUsers";
import ActiveIndicator from "./controls/ActiveIndicator";
import {
  Button,
  createTheme,
  FormControlLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  ThemeProvider,
  Tooltip,
} from "@mui/material";

import css from "./App.module.scss";
import { AvacodoJwt } from "./types";
import Loading from "controls/Loading";
import { useOpenAI } from "hooks/useOpenAI";

const questions = [
  "What is the runtime complexity of the code? ",
  "What is the memory complexity of the code?",
];

function App() {
  const [modal, setModal] = useState(true);
  const [editorVisible, setEditorVisible] = useState(false);
  const [isAILoading, setIsAILoading] = useState<boolean>(false);
  const [aiMessage, setAIMessage] = useState<string>("");
  const [currentSessionName, setCurrentSessionName] = useState("");
  const [interviewer, setInterviewer] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const dispatch = useAppDispatch();
  const {
    text,
    transmitPosition: transmitting,
    connected,
    sessionId,
    editorLanguage,
    aiQuestion,
    userName,
  } = useAppSelector((state) => state.main);
  const { chatCompletions, completions } = useOpenAI();

  useEffect(() => {
    dispatch(setEditorLanguage("javascript"));
    dispatch(setAIQuestion(questions[0]));
  }, [dispatch]);

  useEffect(() => {
    // Check whether the app is loaded using a code
    if (window.location.search.length > 1) {
      const kvp = qs.parse(window.location.search.slice(1));
      const code = kvp["code"];
      localStorage.removeItem("token");
      if (code) {
        setModal(false);
        (async () => {
          const invalidCodeToast = () => {
            setToastMessage(
              "The code is no longer valid. Please request a new code to proceed."
            );
            setShowToast(true);
            // , { position: "top-center", autoClose: false, closeButton: false, closeOnClick: false });
          };
          const response = await dispatch(exchange(code as string));
          if (response.error) {
            invalidCodeToast();
            return;
          }
          const token = response.token!;
          const jwt = jwtDecode<AvacodoJwt>(token);
          localStorage.setItem("token", token);
          const jwtSessionId = jwt["SessionId"];
          const displayName = jwt["DisplayName"];
          if (await dispatch(Sessions.validate(jwtSessionId))) {
            dispatch(joinSession(jwtSessionId));
            dispatch(setUserName(displayName));
            setEditorVisible(true);
          } else {
            invalidCodeToast();
          }
        })();
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (interviewer === true) {
      // For interviewers, start this off disabled
      dispatch(transmitPosition(false));
    }
  }, [dispatch, interviewer]);

  useEffect(() => {
    if (interviewer !== true) return;
    setModal(sessionId === "");

    if (sessionId !== "") {
      (async () => {
        const session = await dispatch(Sessions.get(sessionId));
        setCurrentSessionName(session[0].name);
      })();
    }
  }, [dispatch, sessionId, interviewer]);

  const onClickAnswer = useCallback(async () => {
    const content = aiQuestion.concat(text);
    setIsAILoading(true);
    try {
      let result = await chatCompletions(content);
      setAIMessage(result.choices[0].message?.content || "");
    } catch (e) {
      console.log(e);
    } finally {
      setIsAILoading(false);
    }
  }, [aiQuestion, chatCompletions, text]);

  const onClickResult = useCallback(async () => {
    const content = aiQuestion.concat(text);
    setIsAILoading(true);
    try {
      let result = await completions(content);
      setAIMessage(result.choices[0].text || "");
    } catch (e) {
      console.log(e);
    } finally {
      setIsAILoading(false);
    }
  }, [aiQuestion, completions, text]);

  const onClose = async () => {
    setModal(false);
    setEditorVisible(true);
    const token = localStorage.getItem("token");
    if (token && (await dispatch(validateToken(token)))) {
      setInterviewer(Helpers.isInterviewer(token));
    }
  };
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <>
      <div className={css.container}>
        <ThemeProvider theme={darkTheme}>
          {editorVisible && (
            <div className={css.editorPane}>
              {/* <Editor height={Math.min(windowSize[1] - 200, 650)} /> */}
              <Editor height="calc(100vh - 200px)" />
              <div className={css.editorInfoBar}>
                <div className={css.indicator}>
                  <ActiveIndicator />
                </div>
                <Tooltip title="Coming soon!" placement="right">
                  <Select
                    size="small"
                    disabled={true}
                    value={editorLanguage}
                    onChange={(e) => {
                      dispatch(setEditorLanguage(e.target.value));
                    }}
                  >
                    <MenuItem value="csharp">C#</MenuItem>
                    <MenuItem value="java">Java</MenuItem>
                    <MenuItem value="javascript">Javascript</MenuItem>
                  </Select>
                </Tooltip>
              </div>
            </div>
          )}
          <div className={css.secondaryPane}>
            {connected && !interviewer && <h2>Hello {userName}!</h2>}
            {interviewer && sessionId !== "" && (
              <div className={css.interviewerPanel}>
                <div>
                  <Button
                    onClick={() => {
                      dispatch(leaveSession(sessionId));
                    }}
                  >
                    Leave Session "{currentSessionName}"
                  </Button>
                  <br />
                  <FormControlLabel
                    label="Send cursor position"
                    control={
                      <Switch
                        checked={transmitting}
                        onChange={() => {
                          dispatch(transmitPosition(!transmitting));
                        }}
                      />
                    }
                  />
                  <GuestManager />
                </div>
                <div>
                  <ConnectedUsers />
                </div>
                <div className={css.chatResult}>
                  <div className={css.questionBlock}>
                    <Button className={css.aiButton} onClick={onClickResult}>
                      GetAIResult
                    </Button>
                    <Button className={css.aiButton} onClick={onClickAnswer}>
                      GetAIAnswer
                    </Button>
                    <h4>
                      Q:
                      <Select
                        className={css.dropdown}
                        size="small"
                        value={aiQuestion}
                        onChange={(e) => {
                          dispatch(setAIQuestion(e.target.value));
                        }}
                      >
                        {questions.map((q) => (
                          <MenuItem value={q}>{q}</MenuItem>
                        ))}
                      </Select>
                    </h4>
                  </div>
                  <h4>AI answers:</h4>
                  <p>
                    {isAILoading ? (
                      <Loading enabled={isAILoading}>
                        <div className={css.loading} />
                      </Loading>
                    ) : (
                      aiMessage
                    )}
                  </p>
                </div>
              </div>
            )}
            {modal && <LoginModal onClose={onClose} />}
          </div>

          <Snackbar
            open={showToast}
            message={toastMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={3000}
          />
        </ThemeProvider>
      </div>
    </>
  );
}

export default App;
