import { useRef, useEffect, useCallback } from "react";
import MonacoEditor from "@monaco-editor/react";
import { editor } from "monaco-editor";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import { hubInvoke } from "../state/actions";
import { sendPosition, updateInputText } from "../middleware/signalr";
import Pointer from "./Pointer";

const Editor = ({ height }: { height: number | string }) => {
  const dispatch = useAppDispatch();
  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  const { text, inControl, connectionId, sessionId, userName, editorLanguage } =
    useAppSelector((state) => state.main);
  const positions = useAppSelector((state) => state.positions);


  useEffect(() => {
    if (sessionId !== "" && userName) {
      editorRef.current?.onMouseMove((e) => {
        const { posx, posy } = e.event;
        const pos = `${posx}:${posy}|${userName}`;
        dispatch(sendPosition(pos, sessionId));
      });
    }
  }, [dispatch, sessionId, userName]);

  useEffect(() => {
    editorRef.current?.updateOptions({ readOnly: inControl !== connectionId });
  }, [inControl, connectionId]);

  const onInputChange = useCallback(
    (currentText: string | undefined) => {
      if (inControl === connectionId) {
        dispatch(hubInvoke("sendText", sessionId, currentText));
      }
      dispatch(updateInputText(currentText || ''));
    },
    [connectionId, dispatch, inControl, sessionId]
  );

  return (
    <>
      <MonacoEditor
        theme="vs-dark"
        height={height}
        language={editorLanguage}
        value={text}
        onChange={onInputChange}
        onMount={(editor, _monaco) => {
          editorRef.current = editor;
          // editor.onDidChangeCursorSelection(e => {
          //     const { startLineNumber, endLineNumber, startColumn, endColumn } = e.selection;
          //     if (selectionChanged && (startLineNumber !== endLineNumber || startColumn !== endColumn)) {
          //         selectionChanged(e.selection);
          //     }
          // });
        }}
      />
      {positions &&
        positions.map((p, index) => {
          const [xy, name] = p.split("|");
          const [x, y] = xy.split(":");
          return (
            <Pointer key={index} name={name} x={parseInt(x)} y={parseInt(y)} />
          );
        })}
    </>
  );
};

export default Editor;
