import { useEffect, useState } from "react";
import { Guest } from "../utils/api";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";
import Loading from "../controls/Loading";
import { Button, TextField } from "@mui/material";

type UserDTO = {
    name: any;
    token: any;
};
export default function GuestManager() {
    const dispatch = useAppDispatch();
    const { sessionId } = useAppSelector((state) => state.main);
    const [name, setName] = useState("");
    const [loadingGuests, setLoadingGuests] = useState(false);
    const [guests, setGuests] = useState<UserDTO[]>([]);

    useEffect(() => {
        if (sessionId === '') return;
        // Load guests for the first time
        (async () => {
            setLoadingGuests(true);
            const result = await dispatch(Guest.getGuests(sessionId));

            setGuests(result);
            setLoadingGuests(false);
        })();
    }, [dispatch, sessionId]);

    const addGuest = async () => {
        setLoadingGuests(true);
        const result = await dispatch(Guest.addGuest(name, sessionId));
        setGuests([...guests, { name, token: result }]);
        setName("");
        setLoadingGuests(false);
    };

    function copyLink(code: string) {
        navigator.clipboard.writeText(`${window.location.href}?code=${code}`)
    }

    function removeGuest(guest: any) {

    }

    return <>
        <div>
            <TextField label="Name" value={name} onChange={({ target }) => { setName(target.value) }} />
            <Button disabled={name.length < 1} onClick={() => { addGuest(); }}>Add</Button>
        </div>
        <Loading enabled={loadingGuests}>
            <>{guests.length > 0
                && guests.map((g, i) => <div key={i}>{g.name}
                    <Button onClick={() => { copyLink(g.token) }}>Copy Link</Button>
                    {/* <button onClick={() => { removeGuest(g) }}>Delete</button> */}
                    {/* {`${window.location.href}?code=${g.code}`} */}
                </div>)
            }</>
        </Loading>
    </>;
}