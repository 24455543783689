import React from "react";
import {
  HubConnection,
  JsonHubProtocol,
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
  IHttpConnectionOptions,
  Subject,
  HubConnectionState,
} from "@microsoft/signalr";
import { createAction } from "../state/actions";
import {
  CONNECT_STATE_CHANGED,
  HUB_MESSAGE,
  SESSION_CREATED,
} from "../state/types";
import { Middleware } from "@reduxjs/toolkit";
import { AppDispatch } from "..";
import { Sessions } from "../utils/api";
import { setSession } from "../state/reducer";

let connection: HubConnection;
const signalrMiddleware: Middleware = ({ dispatch }) => {
  return (next) => async (action) => {
    if (!connection && action.type === HUB_MESSAGE) {
      // let transport to fall back to to LongPolling if it needs to
      startConnection(dispatch).then(() => {
        dispatch(
          createAction(`main/${CONNECT_STATE_CHANGED}`, {
            connectionId: connection.connectionId,
            connected: true,
          })
        );
        dispatch(action);
      });
      return;
    }
    if (connection && action.type === "close") {
      await connection.stop();
      return;
    }

    if (action.type === HUB_MESSAGE) {
      const { endpoint, payload } = action.payload;
      let r;
      if (payload.length) r = await connection.invoke(endpoint, ...payload);
      else r = await connection.invoke(endpoint);

      if (r) dispatch(createAction(`main/${endpoint}`, r));
      // return;
    }
    return next(action);
  };
};

async function startConnection(dispatch: AppDispatch) {
  if (connection?.state === HubConnectionState.Connected) {
    return Promise.resolve();
  }
  const transport =
    HttpTransportType.WebSockets | HttpTransportType.LongPolling;

  const options: IHttpConnectionOptions = {
    transport,
    logMessageContent: true,
    logger: LogLevel.Trace,
    // skipNegotiation: true,
    withCredentials: false,
    accessTokenFactory: () => {
      return localStorage.getItem("token")!;
    },
  };

  connection = new HubConnectionBuilder()
    // .configureLogging(LogLevel.Debug)
    .withUrl(process.env.REACT_APP_HUB_URL!, options)
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .build();

  configureMessages(connection, dispatch);

  connection.onclose(() => {
    dispatch(
      createAction(`main/${CONNECT_STATE_CHANGED}`, {
        connectionId: null,
        connected: false,
      })
    );
  });

  return connection.start();
}

function configureMessages(conn: HubConnection, dispatch: AppDispatch) {
  // conn.on('receive', async (d) => {
  //     await dispatch(receiving(true));
  //     await dispatch(createAction('updateText', d));
  // });

  conn.on("textUpdated", (d) => {
    dispatch(createAction("main/update", d));
  });

  conn.on("sessionCreated", (d) => {
    dispatch(createAction(`main/${SESSION_CREATED}`, d));
    dispatch(Sessions.get());
    // dispatch({ type: "HUB_MESSAGE", payload: { endpoint: "joinSession", payload: d }});
    // conn.invoke("joinSession", d);
  });

  // conn.on('connected', connectionId => {
  //     dispatch(createAction(CONNECT_STATE_CHANGED, { connectionId, connected: true}));
  // });

  conn.on("getConnections", (d) => {
    dispatch(createAction("main/getConnections", d));
  });

  conn.on("setControl", (d) => {
    dispatch(createAction("main/setControl", d));
  });

  conn.on("userId", (userId) => {
    dispatch(createAction("main/setUserId", userId));
  });

  conn.on("sendPosition", (position: string) => {
    dispatch(createAction("positions/updatePosition", position));
  });
}

export const updateInputText =
  (input: string) => async (dispatch: AppDispatch) => {
    dispatch(createAction("main/update", input));
  };
export const sendPosition =
  (position: string, sessionId: string) => (_dispatch: any, getState: any) => {
    const { transmitPosition } = getState().main;
    if (transmitPosition)
      connection.invoke("sendPosition", position, sessionId);
  };

export const joinSession =
  (sessionId: string) => async (dispatch: AppDispatch) => {
    startConnection(dispatch).then(() => {
      connection.invoke("joinSession", sessionId);
      dispatch(
        createAction(`main/${CONNECT_STATE_CHANGED}`, {
          connectionId: connection.connectionId,
          connected: true,
        })
      );
      // dispatch(createAction(`main/${SESSION_CREATED}`, sessionId));
      dispatch(setSession(sessionId));
    });
  };

export const leaveSession = (sessionId: string) => (dispatch: AppDispatch) => {
  connection.invoke("leaveSession", sessionId);
  dispatch(setSession(""));
};

export const setInControl =
  (sessionId: string, connectionId: string) => (dispatch: AppDispatch) => {
    connection.invoke("setControl", sessionId, connectionId);
  };

// export const startHubConnection = () => (dispatch: AppDispatch) => {
//     startConnection(dispatch);
// };

export default signalrMiddleware;
