import { setInControl } from '../middleware/signalr';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import ActiveIndicator from './ActiveIndicator';

import css from "./ConnectedUsers.module.scss";

export default function ConnectedUsers() {
    const { connections, inControl, sessionId } = useAppSelector((state) => state.main);
    const dispatch = useAppDispatch();

    return <div className={css.panel}>
        {connections?.length &&
            connections.map((c: string) => {
                const [connectionId, name] = c.split(":");
                return <div className={css.user} key={c} onClick={() => {
                    dispatch(setInControl(sessionId, connectionId));
                }}><ActiveIndicator activeOverride={inControl === connectionId} /> {name}</div>
            })
        }
    </div>;
}