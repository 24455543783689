import { useEffect } from "react";
import { Sessions as SessionsApi } from "../utils/api";
import { joinSession } from "../middleware/signalr";
import { useAppDispatch, useAppSelector } from "../hooks/hooks";

import css from "./Session.module.css";

export default function Sessions() {
    const dispatch = useAppDispatch();
    const sessionList = useAppSelector((state) => state.sessions);

    useEffect(() => {
        (async () => {
            await dispatch(SessionsApi.get());
        })();
    }, [dispatch]);

    return <>
        {sessionList.length > 0 && <ul>
            {sessionList.map((s, i) => <li key={i} value={s.sessionId}>
                <div className={css.sessionName} onClick={() => { dispatch(joinSession(s.sessionId)) }}>{s.name}</div>
                <div className={css.delete} onClick={async () => {
                    await dispatch(SessionsApi.delete(s.sessionId));
                    dispatch(SessionsApi.get());
                }}>Delete</div>
            </li>)}
        </ul>
        }</>;
}