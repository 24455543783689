
import css from "./Loading.module.css";

type LoadingProps = {
    showSpinner?: boolean;
    enabled: boolean;
    blockout?: boolean;
    style?: React.CSSProperties;
    text?: string;
    textStyle?: React.CSSProperties;
    children?: JSX.Element | boolean;
};

export default function Loading({ showSpinner, enabled, blockout, style, text, textStyle, children }:
    LoadingProps) {
    if (showSpinner == null) showSpinner = true;
    return <>
        <div className={css.loadingWrapper} style={style}>{children}
            {enabled &&
                <div className={blockout ? `${css.overlay}, ${css.opaque}` : `${css.overlay}`}>
                    {showSpinner && <>
                        <div className={css.spinnerContainer}>
                            <img className={css.spinner} alt="Loading icon" src={`${process.env.PUBLIC_URL}/AvacodoLogo.png`} />
                            <div style={textStyle}>{text}</div>
                        </div>
                    </>
                    }
                </div>}
        </div>
    </>;
}