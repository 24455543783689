import { faCircleDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useAppSelector } from "../hooks/hooks";
import css from "./ActiveIndicator.module.css";

export default function ActiveIndicator({ name, activeOverride }: { name?: string, activeOverride?: boolean }) {
    const { inControl, connectionId } = useAppSelector(({ main }) => main);
    const [status, setStatus] = useState("");

    useEffect(() => {
        if (activeOverride !== undefined) {
            setStatus(activeOverride ? `${css.active}` : `${css.inactive}`);
        }
        else {
            setStatus(inControl === connectionId ? `${css.active}` : `${css.inactive}`);
        }
    }, [inControl, connectionId, activeOverride]);

    let title = "";
    if (status === `${css.active}`) {
        title = "Send mode";
    } else if (status === `${css.inactive}`) {
        title = "Receive mode";
    } else if (status === `${css.disconnected}`) {
        title = "Disconnected";
    }
    return <>
        <FontAwesomeIcon icon={faCircleDot} className={status}
            title={title} />
        {/* {name} */}
    </>;
}