import jwtDecode from "jwt-decode";
import { AvacodoJwt } from "../types";

export const Helpers = {
    isAdmin: (jwt: string) => Helpers.isInRole(jwt, "admin"),
    isInterviewer: (jwt: string) => (Helpers.isInRole(jwt, "interviewer")),
    isInRole: (jwt: string, ...role: string[]) => {
        const claims = jwtDecode<AvacodoJwt>(jwt);
        let roles: string[] = claims["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        roles = Array.isArray(roles) ? roles : [roles];
        if (Array.isArray(role)) {
            return roles?.length > 0 && roles.some(r => role.includes(r));
        } else {
            console.log("not array");
            // throw "This should never happen.";
            return roles?.length > 0 && roles.find(r => r === role) !== undefined;
        }
    }
};

export default Helpers;